import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'auth-common',
  templateUrl: './auth-common.component.html',
  styleUrl: './auth-common.component.scss'
})
export class AuthCommonComponent implements OnInit {

  backgroundImage = '';
  previewImages = [];
  image = 1;

  @ViewChild('previewElement') previewElement: ElementRef<HTMLImageElement>;

  constructor() {
    this.previewImages[0] = new Image();
    this.previewImages[0].src = '/images/sign-up/preview1.jpg';
    this.previewImages[0].alt = 'Slide with New York weather forecast';
    this.previewImages[1] = new Image();
    this.previewImages[1].src = '/images/sign-up/preview2.jpg';
    this.previewImages[1].alt = 'Slide with employee spotlight';
    this.previewImages[2] = new Image();
    this.previewImages[2].src = '/images/sign-up/preview3.jpg';
    this.previewImages[2].alt = 'Slide with motivational text';
  }

  ngOnInit(): void {
    var rand = Math.random();
    this.backgroundImage = rand < 0.25 ? '/images/sign-up/room1.jpg'
      : rand < 0.5 ? '/images/sign-up/room2.jpg'
      : rand < 0.75 ? '/images/sign-up/room3.jpg'
      : '/images/sign-up/room4.jpg';

    setTimeout(this.cycleImages.bind(this), 7000);
  }

  cycleImages() {
    if (!document.images || !this.previewElement) {
      return;
    }
    this.previewElement.nativeElement.className = 'fade';
    setTimeout(() => {
      this.previewElement.nativeElement.src = this.previewImages[this.image].src;
      this.previewElement.nativeElement.alt = this.previewImages[this.image].alt;
      this.image += 1;
      this.previewElement.nativeElement.className = '';
      if (this.image > 2) {
        this.image = 0;
      }
    }, 500);
    setTimeout(this.cycleImages.bind(this), 7000);
  }

}
