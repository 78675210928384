<div class="app-login">
  <div class="u_overflow-hidden h-100">
    <div class="tv-room">
      <div class="tv-panel aligner-item--top" [ngStyle]="{'background-image': 'url(' + backgroundImage + ')'}">
        <div class="tv-position">
          <div class="tv-frame">
            <img #previewElement src="/images/sign-up/preview1.jpg">
          </div>
        </div>
      </div>
    </div>
    <div class="scroll-panel">
      <div id="auth-panel" class="auth-panel">
        <div class="align-center rise-logo">
          <img
            src="https://s3.amazonaws.com/Rise-Images/Website/rise-logo.svg"
            alt="Rise Vision logo"
          >
        </div>
        <div ui-view></div>
      </div>
    </div>
  </div>
</div>